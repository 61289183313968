import logo from '../assets/logo.png';
import header01 from '../assets/ramen_header.jpg';
import background from "../assets/background.png"

import menu_back from "../assets/menu_catalog/back.jpg"
import menu_front from "../assets/menu_catalog/front.jpg"
import menu_summer from "../assets/menu_catalog/summer.jpg"



// eslint-disable-next-line
export default {
  logo,
  header01,
  background,
  menu_back,
  menu_front,
  menu_summer,
};
